import { useState, useEffect } from "react";
import { getDocument, GlobalWorkerOptions, version } from "pdfjs-dist";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import MDBox from "components/MDBox";

const PDFViewer = ({ presignedURL }: { presignedURL: string }) => {
  if (process.env.REACT_APP_ENV === "local") {
    GlobalWorkerOptions.workerSrc = new URL(
      "pdfjs-dist/build/pdf.worker.min.mjs",
      import.meta.url
    ).toString();
  } else {
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.mjs`;
  }
  const [images, setImages] = useState([]);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        const pdf = await getDocument(presignedURL).promise;
        const numPages = pdf.numPages;
        const imagesArray = [];

        for (let pageNum = 1; pageNum <= numPages; pageNum++) {
          const page = await pdf.getPage(pageNum);
          const viewport = page.getViewport({ scale: 2 });
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          console.log("viewport.height", viewport.height);
          console.log("viewport.width", viewport.width);

          await page.render({ canvasContext: context, viewport }).promise;

          const dataUrl = canvas.toDataURL();
          imagesArray.push(dataUrl);
        }
        setImages(imagesArray);
      } catch (error) {
        console.error("Error loading PDF:", error);
      }
    };

    loadPdf();
  }, [presignedURL]);

  return (
    <MDBox style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      {images.length > 0 ? (
        <Swiper
          modules={[Navigation, Pagination]}
          navigation
          pagination={{ clickable: true }}
          spaceBetween={50}
          slidesPerView={1}
          style={{ width: "100%" }}
        >
          {images.map((src, index) => (
            <SwiperSlide
              key={`slide-${index}`}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <img src={src} alt={`Page ${index + 1}`} style={{ maxWidth: "100%" }} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>Loading pitchdeck...</p>
      )}
    </MDBox>
  );
};

export default PDFViewer;
